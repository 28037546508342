// @ts-nocheck
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useActions } from '../../../../hooks/useActions';
import { useEffect, useState, useRef, useCallback } from 'react';
import { Inspector } from 'react-inspector';
import { Console, Hook, Unhook } from 'console-feed';
import executeAlgorithm from '../methods/algorithm';
import fireToast from '../../central-ui/methods/fireToast';
import { fields } from '../methods/fields';
import { GlobalHotKeys, configure } from 'react-hotkeys';

const LandingPage = () => {
    const [blob, setBlob] = useState({ logs: [], data: { variableFive: { a: 'hallo', b: 'welt' } } });
    const [sessionIdExistsInSavedSessions, setSessionIdExistsInSavedSessions] = useState(false);
    const { pr_get_sessions, pr_delete_sessions, pr_create_session } = useActions();

    const { sessions, sessionsDidInvalidate, createSuccess, deleteSuccess } = useTypedSelector((state) => state.prReducer);

    const runButton = useRef<any>(null);

    configure({
        ignoreTags: ['input', 'select', 'textarea'],
        ignoreEventsCondition: () => false,
    });

    const keyMap = {
        SAVE: ['command+r', 'ctrl+r'],
    };

    const runAlgorithm = useCallback((e) => {
        e.preventDefault();
        if (runButton && runButton.current) {
            runButton.current.click()!;
        }
    }, []);

    const handlers = {
        SAVE: (e: any) => runAlgorithm(e),
    };

    useEffect(() => {
        // INITIALIZATION OF STICKY BLOCKS
        // =======================================================

        new HSStickyBlock('.js-sticky-block', {
            targetSelector: '#header',
            //targetSelector: document.getElementById('header') ? (document.getElementById('header').classList.contains('navbar-fixed') ? '#header' : null) : null,
        });

        // INITIALIZATION OF STEP FORM
        // =======================================================

        new HSStepForm('.js-step-form', {
            finish: () => {
                document.getElementById('uploadResumeStepFormProgress').style.display = 'none';

                document.getElementById('uploadResumeStepFormContent').style.display = 'none';

                document.getElementById('successMessageContent').style.display = 'block';
                scrollToTop('#header');
                const formContainerEg1 = document.getElementById('formContainerEg1');

                formContainerEg1.classList.remove('col-lg-8');

                formContainerEg1.classList.add('col-lg-12');
            },
            onNextStep: function () {
                scrollToTop();
            },
            onPrevStep: function () {
                scrollToTop();
            },
        });

        function scrollToTop(el = '.js-step-form') {
            el = document.querySelector(el);
            window.scrollTo({
                top: el.getBoundingClientRect().top + window.scrollY - 90,
                left: 0,
                behavior: 'smooth',
            });
        }

        // INITIALIZATION OF ADD FIELD
        // =======================================================

        new HSAddField('.js-add-field', {
            addedField: (field) => {
                HSCore.components.HSQuill.init(field.querySelector('.js-quill-dynamic'));
            },
        });

        // INITIALIZATION OF QUILLJS EDITOR
        // =======================================================

        HSCore.components.HSQuill.init('.js-quill');

        // INITIALIZATION OF INPUT MASK
        // =======================================================

        HSCore.components.HSMask.init('.js-input-mask');

        HSCore.components.HSNoUISlider.init('.js-nouislider');

        console.clear();

        Hook(
            window.console,
            (log) => {
                setBlob((currentBlob) => {
                    return { ...currentBlob, logs: [...currentBlob.logs, log] };
                });
            },
            false
        );

        pr_get_sessions();

        return () => Unhook(window.console);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sessionsDidInvalidate) {
            pr_get_sessions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionsDidInvalidate]);

    useEffect(() => {
        if (createSuccess) {
            fireToast('success', 'Session gespeichert');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createSuccess]);

    useEffect(() => {
        if (createSuccess) {
            fireToast('success', 'Sessions gelöscht');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteSuccess]);

    useEffect(() => {
        if (blob.data.sessionId && sessions) {
            let sessionIdDoesNotExist = false;
            for (let session of Object.entries(sessions)) {
                if (session.length > 1 && session[1].data && session[1].data.sessionId && session[1].data.sessionId === blob.data.sessionId) {
                    sessionIdDoesNotExist = true;
                }
            }
            setSessionIdExistsInSavedSessions(sessionIdDoesNotExist);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessions, blob]);

    const selectSessionBlobAsBlob = (sessionIdLoad: string) => {
        for (let session of Object.entries(sessions)) {
            if (session.length > 1 && session[1].data && session[1].data.sessionId && session[1].data.sessionId === sessionIdLoad) {
                let dataTmp = { ...session[1].data };
                dataTmp['firebaseId'] = session[0];
                //dataTmp['sessionId'] = null;
                let logsTmp = [];
                if (session[1].logs) {
                    logsTmp = session[1].logs;
                }
                setBlob({ ...session[1], data: dataTmp, logs: logsTmp });
                fireToast('success', `Session ${sessionIdLoad} geladen`);
            }
        }
    };

    const inputField = (
        label: string,
        field: string,
        columns: string = '12',
        type: string = 'text',
        rowClasses?: string,
        additionalClasses?: string,
        appendText?: string,
        maxLength?: number,
        prependText?: string,
        disabled?: boolean = false
    ) => {
        return (
            <div className={`row ${rowClasses ? rowClasses : ''}`}>
                <div className={`form-group col-lg-${columns}`}>
                    <label className="form-label fw-bold">{label}</label>
                    <div className="input-group mb-3">
                        {prependText ? (
                            <div className="input-group-prepend">
                                <span className="input-group-text">{prependText}</span>
                            </div>
                        ) : null}
                        <input
                            type={type}
                            className={`form-control ${additionalClasses ? additionalClasses : ''}`}
                            placeholder=""
                            value={blob && blob.data[field] ? blob.data[field] : ''}
                            onChange={(e) => {
                                let dataTmp = { ...blob.data };
                                dataTmp[field] = e.target.value;
                                setBlob({
                                    ...blob,
                                    data: dataTmp,
                                });
                            }}
                            pattern={type === 'number' ? '[0-9]+([\\.,][0-9]+)?' : ''}
                            maxLength={maxLength ? maxLength : undefined}
                            id={`field${label}`}
                            style={{ textAlign: `${additionalClasses && additionalClasses === 'text-right' ? 'right' : 'left'}` }}
                            disabled={disabled}
                        />
                        {appendText ? (
                            <div className="input-group-append">
                                <span className="input-group-text">{appendText}</span>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };

    const optionSelect = (label: string, field: string, options: { value: string; label: string }[], disabled?: boolean = false) => {
        return (
            <div className="row mb-3">
                <div className="form-group col-lg-12">
                    <label className="form-label fw-bold">{label}</label>
                    <select
                        className={`form-select`}
                        onChange={(e) => {
                            let dataTmp = { ...blob.data };
                            dataTmp[field] = e.target.value;
                            setBlob({
                                ...blob,
                                data: dataTmp,
                            });
                        }}
                        value={blob && blob.data[field] ? blob.data[field] : ''}
                        disabled={disabled}
                    >
                        <option value=""></option>
                        {options.map((option) => {
                            return <option value={option.value}>{option.label}</option>;
                        })}
                    </select>
                </div>
            </div>
        );
    };

    const switchSelect = (label: string, field: string, columns: string = '12', rowClasses?: string, disabled?: boolean = false) => {
        return (
            <div className={`row ml-4 ${rowClasses ? rowClasses : ''}`}>
                <div className="pl-5">
                    <div className={`form-check form-switch pl-5 mb-3 mt-5 col-lg-${columns}`}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`formSwitch${label}`}
                            onChange={(e) => {
                                let dataTmp = { ...blob.data };
                                dataTmp[field] = e.target.value;
                                setBlob({
                                    ...blob,
                                    data: dataTmp,
                                });
                            }}
                            value={blob && blob.data[field] ? blob.data[field] : false}
                            disabled={disabled}
                        />
                        <label className="form-check-label" htmlFor={`formSwitch${label}`}>
                            {label}
                        </label>
                    </div>
                </div>
            </div>
        );
    };

    const objectDisplay = (label: string, field: string, columns: string = '12', rowClasses?: string) => {
        return (
            <div className={`row mb-3 ${rowClasses ? rowClasses : ''}`}>
                <div className={`form-group col-lg-${columns}`}>
                    <label className="form-label fw-bold">{label}</label>
                    {blob && blob.data[field] ? <Inspector theme="chromeLight" data={blob && blob.data[field] ? blob.data[field] : ''} expandLevel={100} /> : <p>Keine Daten vorhanden</p>}
                </div>
            </div>
        );
    };

    const generateControl = (field: any) => {
        if (field.controlType === 'inputField') {
            return inputField(field.label, field.field, field.columns, field.type, field.rowClasses, field.additionalClasses, field.appendText, field.maxLength, field.prependText, field.disabled);
        }
        if (field.controlType === 'optionSelect') {
            return optionSelect(field.label, field.field, field.options, field.disabled);
        }
        if (field.controlType === 'switchSelect') {
            return switchSelect(field.label, field.field, field.columns, field.rowClasses, field.disabled);
        }
        if (field.controlType === 'objectDisplay') {
            return objectDisplay(field.label, field.field, field.columns, field.rowClasses);
        }
    };

    return (
        <>
            <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
            <div>
                <div className="row">
                    <div className="col-md-8 mb-lg-0 offset-md-2" data-aos="fade-up">
                        <div className="card ">
                            <div className="card-body">
                                <div className="row mb-5  text-center ">
                                    <div className="svg-icon text-primary mb-3"></div>
                                    <h4 className="card-title">Solution</h4>
                                </div>
                                <div className="row">
                                    <div className={`col-md-6 mb-lg-0`} data-aos="fade-up">
                                        <p className="d-grid">
                                            <button
                                                class="btn btn-sm btn-outline-primary"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseExample"
                                                aria-expanded="false"
                                                aria-controls="collapseExample"
                                            >
                                                Save / Load Session
                                            </button>
                                        </p>
                                    </div>
                                    <div className={`col-md-6 mb-lg-0`} data-aos="fade-up">
                                        <div className="row mb-3">
                                            <div className="col-sm-12 mb-sm-0 text-center d-grid">
                                                <button type="button" className="btn btn-sm btn-outline-danger" onClick={(e) => setBlob({ logs: [], data: {} })}>
                                                    Reset Session
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="collapse" id="collapseExample">
                                        <div class="card card-body">
                                            {sessions && Object.entries(sessions).length > 0 ? (
                                                <div className="row mb-3  text-center ">
                                                    <div className="col-12">
                                                        <div className="input-group mb-3">
                                                            <button className="btn  btn-outline-danger" style={{ width: '220px' }} onClick={pr_delete_sessions}>
                                                                <i className="bi bi-trash3 ms-1 mr-2"></i> Delete all Sessions
                                                            </button>
                                                            {/* Select */}
                                                            <select
                                                                className={`form-select`}
                                                                onChange={(e) => {
                                                                    selectSessionBlobAsBlob(e.target.value);
                                                                }}
                                                                value={blob && blob.data && blob.data.sessionId ? blob.data.sessionId : ''}
                                                            >
                                                                <option value="">Restore Session (optional)</option>
                                                                {Object.entries(sessions).map((session: any) => {
                                                                    if (session.length > 1 && session[1].data && session[1].data.sessionId) {
                                                                        return <option value={session[1].data.sessionId}>{session[1].data.sessionId}</option>;
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                })}
                                                            </select>
                                                            {/* End Select */}
                                                            {/* <button className="btn  btn-outline-primary" style={{ width: '220px' }} onClick={selectSessionBlobAsBlob}>
                                                    <i className="bi bi-download ms-1 mr-2"></i> Session Laden
                                                </button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className="row text-center ">
                                                <div className="col-12">
                                                    <div className="input-group ">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="fullName"
                                                            placeholder="Session ID"
                                                            aria-label="Session-Name"
                                                            value={blob && blob.data['sessionId'] ? blob.data['sessionId'] : ''}
                                                            onChange={(e) => {
                                                                let dataTmp = { ...blob.data };
                                                                dataTmp['sessionId'] = e.target.value;
                                                                setBlob({
                                                                    ...blob,
                                                                    data: dataTmp,
                                                                });
                                                            }}
                                                        />
                                                        {!blob.data.sessionId ? (
                                                            <div className="input-group-append">
                                                                <span className="input-group-text text-danger">Please provide a Session ID</span>
                                                            </div>
                                                        ) : null}
                                                        {sessionIdExistsInSavedSessions && blob.data.sessionId ? (
                                                            <div className="input-group-append">
                                                                <span className="input-group-text text-danger">Session ID already exists</span>
                                                            </div>
                                                        ) : null}
                                                        <button
                                                            className="btn btn-outline-primary"
                                                            style={{ width: '220px' }}
                                                            disabled={!blob.data.sessionId || sessionIdExistsInSavedSessions}
                                                            onClick={(e) => pr_create_session(blob)}
                                                        >
                                                            Save Session
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-top border-black-10 my-4" />
                                <div className="row">
                                    <div className={`col-md-${blob && blob.data && blob.data.algorithmExecuted ? '6' : '12'} mb-lg-0`} data-aos="fade-up">
                                        <h5 className="card-title text-center  mb-3">Input Data</h5>
                                        <div className="mb-5">
                                            {fields.map((field: any) => {
                                                if (field.ioType === 'input') {
                                                    return generateControl(field);
                                                }

                                                return null;
                                            })}
                                        </div>
                                        <div className="row">
                                            <div className="col-12 mb-2 mb-sm-0 text-center d-grid">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-success"
                                                    onClick={(e) => {
                                                        let tmpData = executeAlgorithm(blob);
                                                        setBlob({
                                                            ...blob,
                                                            logs: [],
                                                            data: tmpData,
                                                        });
                                                    }}
                                                    ref={runButton}
                                                >
                                                    Start Algorithm (⌘/ctrl+R)
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {blob && blob.data && blob.data.algorithmExecuted ? (
                                        <div className="col-md-6 mb-lg-0" data-aos="fade-up">
                                            {/* <div className="border-top border-black-10 my-4 d-md-none" /> */}
                                            <h5 className="card-title text-center mb-3">Output Data</h5>
                                            {fields.map((field: any) => {
                                                if (field.ioType === 'output') {
                                                    return generateControl(field);
                                                }

                                                return null;
                                            })}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 mb-lg-0" data-aos="fade-up">
                        {blob.logs && blob.logs.length > 0 ? (
                            <>
                                {/* <div className="border-top border-black-10 my-4" /> */}
                                <div className="row">
                                    <div className="col-12 mb-lg-0" data-aos="fade-up">
                                        <div className="row mb-5 mt-5  text-center ">
                                            <div className="col-12 mb-2 mb-sm-0 text-center d-grid">
                                                <button type="button" className="btn btn-sm btn-outline-danger" onClick={(e) => setBlob({ ...blob, logs: [] })}>
                                                    Delete Log
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="border-top border-black-10 my-4" /> */}
                                <h5 className="card-title text-center mb-3">Log</h5>
                                <div className="row">
                                    <div className="col-sm mb-2 mb-sm-0 text-left">
                                        <Console logs={blob.logs} variant="light" />
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LandingPage;
