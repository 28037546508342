export enum ActionType {
    PR_SESSIONS_GET_SUCCESS = 'pr_sessions_get_success',
    PR_SESSIONS_CREATE_SUCCESS = 'pr_sessions_create_success',
    PR_SESSIONS_UPDATE_SUCCESS = 'pr_sessions_update_success',
    PR_SESSIONS_DELETE_SUCCESS = 'pr_sessions_delete_success',
    RI_HOMEPAGE_OBJECTS_GET_SUCCESS = 'ri_homepage_objects_get_success',
    RI_HOMEPAGE_DETAIL_ENTRY_GET_PENDING = 'ri_homepage_detail_entry_get_pending',
    RI_HOMEPAGE_DETAIL_ENTRY_GET_SUCCESS = 'ri_homepage_detail_entry_get_success',
    RI_HOMEPAGE_REQUEST_SUCCESS = 'ri_homepage_request_success',
}
