import { useEffect } from 'react';
import { CopyBlock, vs2015 } from 'react-code-blocks';
import { fields } from '../methods/fields';

const AlgorithmPage = () => {
    useEffect(() => {}, []);
    var codeAsText = `
export class Transform {
    numberOfCycles: number;
    constructor() {
        this.numberOfCycles = 0;
    }

    palindrome(inputValue: number) {
        let currentValue: number = inputValue;
        while (!this.isPalindrome(currentValue) && currentValue <= 1000000000) {
            this.numberOfCycles++;
            console.log(\\\`Iteration \\\${this.numberOfCycles}:\\\`);
            console.log(currentValue.toString() + '+' + this.reverseNumber(currentValue).toString() + '=' + (parseInt(currentValue.toString()) + this.reverseNumber(currentValue)).toString());
            currentValue = parseInt(currentValue.toString()) + this.reverseNumber(currentValue);
        }

        console.log('Result: ' + (this.isPalindrome(currentValue) ? currentValue : -1).toString());
        console.log('Number of Cycles: ' + this.numberOfCycles.toString());

        return this.isPalindrome(currentValue) ? currentValue : -1;
    }

    isPalindrome(valueToBeChecked: number) {
        const isPalindromeArray = valueToBeChecked
            .toString()
            .split('')
            .map((currentValue: string, currentIndex: number, array: any) => {
                return currentValue === array[array.length - currentIndex - 1];
            });

        return !isPalindromeArray.includes(false);
    }

    reverseNumber(initialValue: number) {
        const reversedValue = Number(initialValue)
            .toString()
            .split('')
            .reduce((previousValue: string, currentValue: string, currentIndex: number, array: any) => currentValue + previousValue);

        return Number(reversedValue);
    }
}

const executeAlgorithm = (blob: any) => {
    // All data is included in the data properties of the blob input object
    const { data } = blob;

    data.algorithmExecuted = true;

    const transformObject = new Transform();

    data.result = transformObject.palindrome(data.palindromeInput).toString();
    data.numberOfCycles = transformObject.numberOfCycles.toString();

    return data;
};

export default executeAlgorithm;
    
    
    
`;

    return (
        <>
            <div>
                <div className="row mb-3">
                    <div className="col-md-8 mb-lg-0 offset-md-2" data-aos="fade-up">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="svg-icon text-primary mb-3"></div>
                                <h4 className="card-title text-center  mb-3">Algorithmus</h4>
                                <div className="row mb-5">
                                    <div className="col-md-6 mb-lg-0 mb-3" data-aos="fade-up">
                                        {/* List Group */}
                                        <h5 className="card-title text-center  mb-3">Input Daten</h5>
                                        <ul className="list-group list-group-striped">
                                            {fields.map((field: any) => {
                                                if (field.ioType === 'input') {
                                                    return (
                                                        <li className="list-group-item">
                                                            <i className="bi bi-box-arrow-in-down list-group-icon" /> {field.label} ({field.field}) - {field.description}
                                                        </li>
                                                    );
                                                }

                                                return null;
                                            })}
                                        </ul>
                                        {/* End List Group */}
                                    </div>
                                    <div className="col-md-6 mb-lg-0" data-aos="fade-up">
                                        {/* List Group */}
                                        <h5 className="card-title text-center  mb-3">Output Daten</h5>
                                        <ul className="list-group list-group-striped">
                                            {fields.map((field: any) => {
                                                if (field.ioType === 'output') {
                                                    return (
                                                        <li className="list-group-item">
                                                            <i className="bi bi-box-arrow-down list-group-icon" /> {field.label} ({field.field}) - {field.description}
                                                        </li>
                                                    );
                                                }

                                                return null;
                                            })}
                                        </ul>
                                        {/* End List Group */}
                                    </div>
                                </div>
                                <div className="row mb-3 pt-2">
                                    <h5 className="card-title text-center  mb-3">Algorithmus-Code</h5>
                                    <CopyBlock text={codeAsText} language={'typescript'} showLineNumbers={false} theme={vs2015} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AlgorithmPage;
