import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const Navbar = () => {
    const history = useHistory();
    const location = useLocation();

    return (
        <header id="header" className="navbar navbar-expand-xl navbar-end navbar-light bg-white navbar-sticky-top">
            <div className="container">
                <nav className="js-mega-menu navbar-nav-wrap">
                    {/* <Link to="/" className={`navbar-brand`} onClick={(e) => history.push('/')}>
                        Pascal Rauprecht
                    </Link> */}
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-default">
                            <i className="bi-list"></i>
                        </span>
                        <span className="navbar-toggler-toggled">
                            <i className="bi-x"></i>
                        </span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/" className={`nav-link ${location.pathname === '/' ? 'active text-blue' : null}`} onClick={(e) => history.push('/')}>
                                    Solution
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/algorithmus" className={`nav-link ${location.pathname === '/algorithmus' ? 'active text-blue' : null}`} onClick={(e) => history.push('/algorithmus')}>
                                    Algorithm
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/code" className={`nav-link ${location.pathname === '/code' ? 'active text-blue' : null}`} onClick={(e) => history.push('/code')}>
                                    Download Code
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/aufgabe" className={` nav-link ${location.pathname.startsWith('/aufgabe') ? 'active text-blue' : null}`} onClick={(e) => history.push('/aufgabe')}>
                                    Task
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Navbar;
