// import { useTypedSelector } from '../../../../hooks/useTypedSelector';
// import { useActions } from '../../../../hooks/useActions';
import { useEffect } from 'react';

const TaskPage = () => {
    useEffect(() => {
        // INITIALIZATION OF STICKY BLOCKS
        // =======================================================
        // @ts-ignore
        new HSStickyBlock('.js-sticky-block', {
            // @ts-ignore
            targetSelector: '#header',
            //targetSelector: document.getElementById('header') ? (document.getElementById('header').classList.contains('navbar-fixed') ? '#header' : null) : null,
        });

        // INITIALIZATION OF STEP FORM
        // =======================================================
        // @ts-ignore
        new HSStepForm('.js-step-form', {
            finish: () => {
                // @ts-ignore
                document.getElementById('uploadResumeStepFormProgress').style.display = 'none';
                // @ts-ignore
                document.getElementById('uploadResumeStepFormContent').style.display = 'none';
                // @ts-ignore
                document.getElementById('successMessageContent').style.display = 'block';
                scrollToTop('#header');
                const formContainerEg1 = document.getElementById('formContainerEg1');
                // @ts-ignore
                formContainerEg1.classList.remove('col-lg-8');
                // @ts-ignore
                formContainerEg1.classList.add('col-lg-12');
            },
            onNextStep: function () {
                scrollToTop();
            },
            onPrevStep: function () {
                scrollToTop();
            },
        });

        function scrollToTop(el = '.js-step-form') {
            // @ts-ignore
            el = document.querySelector(el);
            window.scrollTo({
                // @ts-ignore
                top: el.getBoundingClientRect().top + window.scrollY - 90,
                left: 0,
                behavior: 'smooth',
            });
        }

        // INITIALIZATION OF ADD FIELD
        // =======================================================
        // @ts-ignore
        new HSAddField('.js-add-field', {
            // @ts-ignore
            addedField: (field) => {
                // @ts-ignore
                HSCore.components.HSQuill.init(field.querySelector('.js-quill-dynamic'));
            },
        });

        // INITIALIZATION OF QUILLJS EDITOR
        // =======================================================
        // @ts-ignore
        HSCore.components.HSQuill.init('.js-quill');

        // INITIALIZATION OF INPUT MASK
        // =======================================================
        // @ts-ignore
        HSCore.components.HSMask.init('.js-input-mask');
        // @ts-ignore
        HSCore.components.HSNoUISlider.init('.js-nouislider');
    }, []);

    return (
        <>
            <div>
                <div className="row mb-3">
                    <div className="col-md-8 mb-lg-0 offset-md-2" data-aos="fade-up">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="svg-icon text-primary mb-3"></div>
                                <h4 className="card-title mb-3 text-center">Task</h4>
                                <p className="card-text text-body">
                                    Part 1
                                    <br />
                                    <br />
                                    A palindrome is a number that is the same whether it is read from left-to-right or right-to-left. For example, 121 and 34543 are both palindromes. It turns out that
                                    nearly every integer can be transformed into a palindrome by reversing its digits and adding it to the original number. If that does not create a palindrome, add
                                    the reverse of the new number to itself. A palindrome is created by repeating the process of reversing the number and adding it to itself until the number is a
                                    palindrome.
                                    <br />
                                    <br />
                                    Create a class Transform that contains the method palindrome, which takes a number N that is to be transformed and returns a number that is the resultant palindrome
                                    from this process. Of course if N is already a palindrome, return it without changing it. Though it is theorized that all numbers can be transformed to palindromes
                                    in this way, some numbers do not converge in a reasonable amount of time. For instance, 196 has been carried out to 26,000 digits without finding a palindrome. So
                                    if the method finds that the resultant palindrome must be greater than 1,000,000,000, return the special value -1 instead.
                                    <br />
                                    <br />
                                    DEFINITION
                                    <br />
                                    Class: Transform
                                    <br />
                                    Method: palindrome
                                    <br />
                                    Parameters: int
                                    <br />
                                    Returns: int
                                    <br />
                                    Method signature (be sure your method is public): int palindrome(int N);
                                    <br />
                                    <br />
                                    NOTES
                                    <br />
                                    - Leading zeroes are never considered part of a number when it is reversed. For instance, 12's reverse will always be 21 regardless of whether it is represented as
                                    12, 012, or 0012. Examples with leading zeroes use the leading zeroes for clarity only.
                                    <br />
                                    <br />
                                    Inputs are valid if all of the following criteria are met:
                                    <br />
                                    - N will be between 1 and 10000 inclusive.
                                    <br />
                                    <br />
                                    EXAMPLES
                                    <br />
                                    Worked examples:
                                    <br />
                                    Example 1:
                                    <br />
                                    N = 28
                                    <br />
                                    28 + 82 = 110
                                    <br />
                                    110 + 011 = 121, a palindrome. Return 121
                                    <br />
                                    <br />
                                    Example 2:
                                    <br />
                                    N = 51
                                    <br />
                                    51 + 15 = 66, a palindrome. Return 66
                                    <br />
                                    <br />
                                    Further examples:
                                    <br />
                                    Example 3: N = 11, return 11
                                    <br />
                                    Example 4: N = 607, return 4444
                                    <br />
                                    Example 5: N = 196, return -1
                                    <br />
                                    <br />
                                    Please add tests to verify your code.
                                    <br />
                                    <br />
                                    <br />
                                    Part 2
                                    <br />
                                    <br />
                                    Develop a simple GUI which has a control on it to accept an integer. After pressing a button, the palindrome of this integer should be calculated and the result
                                    should be displayed in the GUI. The number of cycles needed to calculate the palindrome should also be displayed. With a second button, it should be possible to
                                    save the result to a DB. This DB would consist of only one simple table with 3 columns: The integer used as input to calculate the palindrome, the resulting
                                    palindrome and the number of cycles needed to calculate the palindrome.
                                    <br />
                                    <br />
                                    With a third button it should be possible to display the whole content of this table on the GUI.
                                    <br />
                                    <br />
                                    Remarks: This part of the task is not so much about designing a beautiful high end GUI, but more about the design of a small application with different “modules” or
                                    “layers”. So keep things simple. At a pinch, you could also use an Excel-file or simple text file as data store and a console as UI.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TaskPage;
