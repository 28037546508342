import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';

export const pr_get_sessions = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `https://hms-task-default-rtdb.europe-west1.firebasedatabase.app/.json`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.PR_SESSIONS_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const pr_delete_sessions = () => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `https://hms-task-default-rtdb.europe-west1.firebasedatabase.app/.json`,
            method: 'delete',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.PR_SESSIONS_DELETE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const pr_create_session = (blob: any) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const { doRequest } = useRequest({
            url: `https://hms-task-default-rtdb.europe-west1.firebasedatabase.app/.json`,
            method: 'post',
            body: { ...blob },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.PR_SESSIONS_CREATE_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_homepage_send_request = (anrede: string, firstName: string, lastName: string, eMail: string, telephone: string, customText: string, objectId: string) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const host = window.location.host;

        dispatch({
            type: ActionType.RI_HOMEPAGE_DETAIL_ENTRY_GET_PENDING,
        });

        const { doRequest } = useRequest({
            url: `https://${
                host === 'mockups.benit.cloud' || host === 'rauprecht-immobilien.de' || host === 'www.rauprecht-immobilien.de' ? 'benit.cloud' : 'benit.dev'
            }/api/ri/real-estate/public/info-request`,
            method: 'post',
            body: { anrede, firstName, lastName, eMail, telephone, customText, objectId },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_HOMEPAGE_REQUEST_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};
