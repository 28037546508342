const Footer = () => {
    return (
        <footer className="">
            <div className="container pb-1 pb-lg-3">
                <div className="border-top border-black-10 my-4" />
                <div className="row mb-3">
                    <div className="col-sm mb-3 mb-sm-0 text-center">
                        {/* Socials */}
                        <ul className="list-inline list-separator list-separator-dark mb-0">
                            <li className="list-inline-item">Pascal Rauprecht</li>
                            <li className="list-inline-item">
                                <a className="link-sm link-dark" href="https://goo.gl/maps/tcuXQ8VSRxZxf4fXA">
                                    <i className="bi-geo-alt-fill me-1" /> Hermann-Wellensiek-Str. 18, 67346 Speyer
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="link-sm link-dark" href="tel:+4915165132732">
                                    <i className="bi-telephone-inbound-fill me-1" /> (0151) 65132732
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="link-sm link-dark" href="mailto:pascal@rauprecht.de">
                                    <i className="bi-envelope-fill me-1" /> pascal@rauprecht.de
                                </a>
                            </li>
                        </ul>
                        {/* End Socials */}
                    </div>
                    {/* <div className="col-sm-auto">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <a className="btn btn-soft-dark btn-xs btn-icon" href="mailto:pascal@rauprecht.de">
                                    <i className="bi-envelope" />
                                </a>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
