export const fields = [
    {
        description: 'Input Value for Palindrome Process',
        ioType: 'input',
        controlType: 'inputField',
        label: 'Input Value',
        field: 'palindromeInput',
        columns: '12',
        type: 'number',
        rowClasses: '',
        additionalClasses: null,
        appendText: null,
        maxLength: null,
        prependText: null,
        disabled: false,
    },
    // {
    //     description: 'blubby',
    //     ioType: 'input',
    //     controlType: 'inputField',
    //     label: 'Variable2',
    //     field: 'variableTwo',
    //     columns: '12',
    //     type: 'number',
    //     rowClasses: '',
    //     additionalClasses: 'text-right',
    //     appendText: 'Variable',
    //     maxLength: null,
    //     prependText: null,
    //     disabled: false,
    // },
    // {
    //     description: 'blubby',
    //     ioType: 'input',
    //     controlType: 'optionSelect',
    //     label: 'Variable3',
    //     field: 'variableThree',
    //     options: [
    //         { value: 'RESERVED', label: 'reserviert' },
    //         { value: 'SOLD', label: 'verkauft' },
    //         { value: 'RENTED', label: 'vermietet' },
    //     ],
    //     disabled: false,
    // },
    // {
    //     description: 'blubby',
    //     ioType: 'input',
    //     controlType: 'switchSelect',
    //     label: 'Variable4',
    //     field: 'variableFour',
    //     columns: '12',
    //     rowClasses: '',
    //     disabled: false,
    // },
    // {
    //     description: 'blubby',
    //     ioType: 'input',
    //     controlType: 'objectDisplay',
    //     label: 'Variable5',
    //     field: 'variableFive',
    //     columns: '12',
    //     rowClasses: '',
    // },
    {
        description: 'Output Value of Palindrome Algorithm',
        ioType: 'output',
        controlType: 'inputField',
        label: 'Result',
        field: 'result',
        columns: '12',
        type: 'number',
        rowClasses: '',
        additionalClasses: null,
        appendText: null,
        maxLength: null,
        prependText: null,
        disabled: true,
    },
    {
        description: 'Number of Cycles until Palindrome was reached / Loop stopped because of value > 1,000,000,000',
        ioType: 'output',
        controlType: 'inputField',
        label: 'Number of Cycles',
        field: 'numberOfCycles',
        columns: '12',
        type: 'number',
        rowClasses: '',
        additionalClasses: null,
        appendText: null,
        maxLength: null,
        prependText: null,
        disabled: true,
    },
    // {
    //     description: 'blubby',
    //     ioType: 'output',
    //     controlType: 'objectDisplay',
    //     label: 'Result2',
    //     field: 'resultTwo',
    //     columns: '12',
    //     rowClasses: '',
    // },
];
