import { Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import LandingPage from '../../../pages/components/LandingPage';
import TaskPage from '../../../pages/components/TaskPage';
import Footer from './MainWindow/Footer/Footer';
import Navbar from './MainWindow/Navbar/Navbar';
import CodePage from '../../../pages/components/CodePage';
import AlgorithmPage from '../../../pages/components/AlgorithmPage';
import GamePage from '../../../pages/components/GamePage';

const MainWrapper = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {location.pathname === '/game' ? (
                <Route path="/game" exact component={GamePage} />
            ) : (
                <>
                    <Navbar />
                    <main id="content" role="main" style={{ marginTop: '70px' }}>
                        <div className="container-fluid">
                            <Route path="/" exact component={LandingPage} />
                            <Route path="/aufgabe" exact component={TaskPage} />
                            <Route path="/code" exact component={CodePage} />
                            <Route path="/algorithmus" exact component={AlgorithmPage} />
                        </div>
                    </main>
                    <Footer />
                </>
            )}
        </>
    );
};

export default MainWrapper;
