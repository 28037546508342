import { useEffect } from 'react';

const CodePage = () => {
    useEffect(() => {}, []);

    return (
        <>
            <div>
                <div className="row mb-3">
                    <div className="col-md-8 mb-lg-0 offset-md-2" data-aos="fade-up">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="svg-icon text-primary mb-3"></div>
                                <h4 className="card-title text-center  mb-3">Code</h4>
                                <div className="text-center mb-3">
                                    <a type="button" className="btn btn-outline-primary" onClick={(e) => null} href="/Code.zip">
                                        <i className="bi bi-download"></i> Download Code
                                    </a>
                                </div>
                                <div className="text-center mb-3">
                                    <a type="button" className="btn btn-outline-primary" onClick={(e) => null} href="/Source_Code.zip">
                                        <i className="bi bi-download"></i> Download Source Code Only (with this you will not be able to do npm run)
                                    </a>
                                </div>
                                <ul className="step">
                                    <li className="step-item">
                                        <div className="step-content-wrapper">
                                            <span className="step-icon step-icon-soft-primary">1</span>
                                            <div className="step-content">
                                                <h4>Download Code ZIP</h4>
                                                <p className="step-text">Click on "Download Code" or take the ZIP from the e-mail</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="step-item">
                                        <div className="step-content-wrapper">
                                            <span className="step-icon step-icon-soft-primary">2</span>
                                            <div className="step-content">
                                                <h4>Unzip ZIP</h4>
                                                <p className="step-text">Unzip the ZIP</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="step-item">
                                        <div className="step-content-wrapper">
                                            <span className="step-icon step-icon-soft-primary">3</span>
                                            <div className="step-content">
                                                <h4>Open a terminal and navigate into unzipped folder</h4>
                                                <p className="step-text">cd into unzipped folder</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="step-item">
                                        <div className="step-content-wrapper">
                                            <span className="step-icon step-icon-soft-primary">4</span>
                                            <div className="step-content">
                                                <h4>Install node modules</h4>
                                                <p className="step-text">run "npm install" in the console</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="step-item">
                                        <div className="step-content-wrapper">
                                            <span className="step-icon step-icon-soft-primary">5</span>
                                            <div className="step-content">
                                                <h4>Run the application</h4>
                                                <p className="step-text">run "npm run local" in the console</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="step-item">
                                        <div className="step-content-wrapper">
                                            <span className="step-icon step-icon-soft-primary">6</span>
                                            <div className="step-content">
                                                <h4>Open the application</h4>
                                                <p className="step-text">
                                                    Open a browser (ideally Chrome as I have used Chrome) and navigate to{' '}
                                                    <a href="http://localhost:3000/" target="_blank" rel="noreferrer">
                                                        http://localhost:3000/
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="step-item">
                                        <div className="step-content-wrapper">
                                            <span className="step-icon step-icon-soft-primary">7</span>
                                            <div className="step-content">
                                                <h4>Run the test suite</h4>
                                                <p className="step-text">run "npm run test" in order to execute all jest tests</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CodePage;
