export class Transform {
    numberOfCycles: number;
    constructor() {
        this.numberOfCycles = 0;
    }

    palindrome(inputValue: number) {
        let currentValue: number = inputValue;
        while (!this.isPalindrome(currentValue) && currentValue <= 1000000000) {
            this.numberOfCycles++;
            console.log(`Iteration ${this.numberOfCycles}:`);
            console.log(currentValue.toString() + '+' + this.reverseNumber(currentValue).toString() + '=' + (parseInt(currentValue.toString()) + this.reverseNumber(currentValue)).toString());
            currentValue = parseInt(currentValue.toString()) + this.reverseNumber(currentValue);
        }

        console.log('Result: ' + (this.isPalindrome(currentValue) ? currentValue : -1).toString());
        console.log('Number of Cycles: ' + this.numberOfCycles.toString());

        return this.isPalindrome(currentValue) ? currentValue : -1;
    }

    isPalindrome(valueToBeChecked: number) {
        const isPalindromeArray = valueToBeChecked
            .toString()
            .split('')
            .map((currentValue: string, currentIndex: number, array: any) => {
                return currentValue === array[array.length - currentIndex - 1];
            });

        return !isPalindromeArray.includes(false);
    }

    reverseNumber(initialValue: number) {
        const reversedValue = Number(initialValue)
            .toString()
            .split('')
            .reduce((previousValue: string, currentValue: string, currentIndex: number, array: any) => currentValue + previousValue);

        return Number(reversedValue);
    }
}

const executeAlgorithm = (blob: any) => {
    // All data is included in the data properties of the blob input object
    const { data } = blob;

    data.algorithmExecuted = true;

    const transformObject = new Transform();

    data.result = transformObject.palindrome(data.palindromeInput).toString();
    data.numberOfCycles = transformObject.numberOfCycles.toString();

    return data;
};

export default executeAlgorithm;
