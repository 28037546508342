import { ActionType } from '../action-types';
import { Action } from '../actions';

interface LandingPageState {
    sessions: any;
    sessionsDidInvalidate: boolean;
    createSuccess: boolean;
    deleteSuccess: boolean;
}

const initialState = {
    sessions: null,
    sessionsDidInvalidate: false,
    createSuccess: false,
    deleteSuccess: false,
};

const reducer = (state: LandingPageState = initialState, action: Action): LandingPageState => {
    switch (action.type) {
        case ActionType.PR_SESSIONS_GET_SUCCESS:
            return {
                ...state,
                sessions: action.payload,
                sessionsDidInvalidate: false,
                createSuccess: false,
                deleteSuccess: false,
            };
        case ActionType.PR_SESSIONS_DELETE_SUCCESS:
            return {
                ...state,
                sessionsDidInvalidate: true,
                deleteSuccess: true,
            };
        case ActionType.PR_SESSIONS_CREATE_SUCCESS:
            return {
                ...state,
                sessionsDidInvalidate: true,
                createSuccess: true,
            };
        default:
            return state;
    }
};

export default reducer;
